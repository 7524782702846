import React from 'react';

const Pressure = () => {
  return (
    <div>
      <br></br>
      <br></br>
      <br></br>
      <h1>Pressure Page</h1>
      <p>This is the content of Pressure.</p>
    </div>
  );
};

export default Pressure;
