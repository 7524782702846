/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import classNames from 'classnames';
import classes from './Navbar.module.css';
import Overview from './pages/overview/Overview';
import Pressure from './pages/pressure/Pressure';
import Temperature from './pages/temperature/Temperature';
import SkinHydration from './pages/skin-hydration/SkinHydration';
import Steps from './pages/steps/Steps';


const Navbar = ({ onLogout }) => {
  
  const [currentPage, setCurrentPage] = useState('overview');
  const [activeLink, setActiveLink] = useState('overview');

  const renderPage = () => {
    switch (currentPage) {
      case 'overview':
        return <Overview />;
      case 'pressure':
        return <Pressure />;
      case 'temperature':
        return <Temperature />;
      case 'skin-hydration':
        return <SkinHydration />;
      case 'steps':
        return <Steps />;
      default:
        return <Overview />;
    }
  };

  const resetPage = () => {
    setCurrentPage('overview');
  }

  const handleLinkClick = (link) => {
    setActiveLink(link);
  }

  // Function to get the class for a link
  const getLinkClass = (link) => {
    return classNames(classes.nav_link, {
      [classes.active]: link === activeLink
    });
  };

  return (
    <React.Fragment>
      <nav className={classes.nav_bar}>
        <a className={getLinkClass('overview')} onClick={() => {setCurrentPage('overview'); handleLinkClick('overview')}}>Overview</a>
        <a className={getLinkClass('pressure')} onClick={() => {setCurrentPage('pressure'); handleLinkClick('pressure')}}>Feet Pressure</a>
        <a className={getLinkClass('temperature')} onClick={() => {setCurrentPage('temperature'); handleLinkClick('temperature')}}>Feet Temperature</a>
        <a className={getLinkClass('skin-hydration')} onClick={() => {setCurrentPage('humidity'); handleLinkClick('humidity')}}>Skin Hydration</a>
        <a className={getLinkClass('steps')} onClick={() => {setCurrentPage('steps'); handleLinkClick('steps')}}>Step Count</a>
        <a className={classes.nav_link} onClick={() => {onLogout(); resetPage()}}>logout</a>
      </nav>
      {renderPage()}
    </React.Fragment>
  );
};

export default Navbar;