import React, { useState, useEffect } from "react";
import classes from "./StepsPanel.module.css";
import ButtonGroup from "../ButtonGroup";
import { ReactComponent as StepCountSvg } from '../../../../../assets/svg/steps-count.svg';
import TimerSlider from "../TimerSlider";
import axios from "axios";
import CountUp from 'react-countup';
import { getPastDate } from "../../../../../utils/utils";

const BASE_URL = process.env.REACT_APP_API_BASE_URL || "https://data-api.sandbox.d-sole.com";
const URL = BASE_URL + "/v1/fetch/shoe/123456/step?period=P1W";
const TOKEN = process.env.REACT_APP_API_TOKEN  || "dsole_me_2024";

const StepsPanel = ({title, marks}) => {

  const [totalCount, setTotalCount] = useState(0);

    const fetchStepData = async (dateTime) => {
      try {
        const response = await axios.get(URL + `&time=${dateTime}`, {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
          },
        });
        const data = response.data;
        setTotalCount(data.step_count);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    useEffect(() => {
      fetchStepData(getPastDate(0));
    }, []);

    const onSlideChangeHandler = (value) => {
  
      switch(value) {
        case 0:
          fetchStepData(getPastDate(35)); break;
        case 1:
          fetchStepData(getPastDate(31.5)); break;
        case 2:
          fetchStepData(getPastDate(28)); break;
        case 3:
          fetchStepData(getPastDate(24.5)); break;
        case 4:
          fetchStepData(getPastDate(21)); break;
        case 5:
          fetchStepData(getPastDate(17.5)); break;
        case 6:
          fetchStepData(getPastDate(14)); break;
        case 7:
          fetchStepData(getPastDate(10.5)); break;
        case 8:
          fetchStepData(getPastDate(7)); break;
        case 9:
          fetchStepData(getPastDate(3.5)); break;
        default :
          fetchStepData(getPastDate(0));
      }
    };

    
    return (
        <React.Fragment>
            <div className={classes.container}>
                <div className={classes.edge_items}>
                    <label className={classes.label_1}>{title}</label>
                    <ButtonGroup />
                </div>
                <div>
                    <StepCountSvg className={classes.svg}/>
                </div>
                <div className={classes.step_count}><CountUp start={0} end={totalCount} duration={2} separator="" /></div>
                <h2 className={classes.steps_label}>steps</h2>
                <div className={classes.edge_items}>
                    <label className={classes.side_labels}>Left</label>
                    <label className={classes.side_labels}>Right</label>
                </div>
                <div className={classes.week_slider}>
                    <TimerSlider marks={marks} onSlideChange={onSlideChangeHandler} />
                </div>
            </div>
            
        </React.Fragment>
    )

}

export default StepsPanel;