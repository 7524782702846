import React from 'react';
import classes from './Bar.module.css';
import logo from '../../assets/images/logo.png';

const Navbar = () => {
  return (
    <nav className={classes.bar}>
      <img src={logo} alt="Logo" className={classes.bar_logo} />
      <button className={classes.support_button}>Get Support</button>
    </nav>
  );
};

export default Navbar;