import React, { useState } from 'react';
import classes from "./ButtonGroup.module.css";

const ButtonGroup = () => {
    const [active, setActive] = useState('WEEKLY');

    const handleClick = (btn) => {
        setActive(btn);
    };

    return (
        <div className={classes.container}>
            <button className={classes.left_btn}
                onClick={() => handleClick('HOURLY')} 
                style={{ 
                    color: active === 'HOURLY' ? 'white' : '#c8c8c8',
                    backgroundColor: active === 'HOURLY' ? '#00a3ad' : '#f4f4f4'
                }}>
                HOURLY
            </button>
            <button
                onClick={() => handleClick('DAILY')} 
                style={{ 
                    color: active === 'DAILY' ? 'white' : '#c8c8c8',
                    backgroundColor: active === 'DAILY' ? '#00a3ad' : '#f4f4f4'
                }}>
                DAILY
            </button>
            <button className={classes.right_btn}
                onClick={() => handleClick('WEEKLY')} 
                style={{ 
                    color: active === 'WEEKLY' ? 'white' : '#c8c8c8', 
                    backgroundColor: active === 'WEEKLY' ? '#00a3ad' : '#f4f4f4'
                }}>
                WEEKLY
            </button>            
        </div>
    );
};

export default ButtonGroup;
