import React from "react";
import classes from "./PanelItem.module.css";


function PanelItem ({icon, label}) {

    return (
        <div className={classes.panel_item}>
        <img className={classes.panel_item_image} src={icon} alt="" />
        <span className={classes.panel_item_label}>{label}</span>
      </div>        
    )

}

export default PanelItem;