 export const getPastDate = (daysAgo) => {
    const currentDate = new Date();

    // Get the whole number of days and fractional part (for hours)
    const wholeDays = Math.floor(daysAgo); // Whole days (e.g., 10 from 10.5)
    const fractionOfDay = daysAgo - wholeDays; // Fractional part (e.g., 0.5 from 10.5)
  
    // Subtract whole days
    currentDate.setDate(currentDate.getDate() - wholeDays);
  
    // Subtract fractional day as hours
    const hoursToSubtract = Math.floor(fractionOfDay * 24); // Convert fraction to hours (0.5 * 24 = 12)
    currentDate.setHours(currentDate.getHours() - hoursToSubtract);
  
    return currentDate.toISOString(); // Return in ISO format      
}