import React from "react";
import classes from "./Support.module.css";
import QuestionIcon from "../../../assets/svg/question.svg";
import SupportButton from "./SupportButton";

function Support() {
    return (
        <div className={classes.support_container}>
            <img src={QuestionIcon} alt="Question" className={classes.support_image} />
            <label>Need help?</label>
            <label className={classes.support_label}>Please contact your admin</label>
            <SupportButton />
        </div>
    )
}

export default Support;