import React from "react";
import HomeDashboard from "./HomeDashboard";
import classes from "./Panel.module.css";
import logo from "../../../assets/images/logo.png";
import PanelItem from "./PanelItem";
import CalendarIcon from "../../../assets/svg/calendar.svg"
import PatientIcon from "../../../assets/svg/my-patient.svg"
import ClockIcon from "../../../assets/svg/clock.svg"
import ProfileIcon from "../../../assets/svg/profile.svg"
import Support from "./Support";



function Panel () {
    return ( 
        <React.Fragment>
            <div className={classes.panel} >
              <img src={logo} alt="Logo" className={classes.logo} />
              <HomeDashboard className={classes.button}/>
              <PanelItem className={classes.item} icon={CalendarIcon} label={'Calendar'} />
              <PanelItem icon={PatientIcon} label={'My Patient'} />
              <PanelItem icon={ClockIcon} label={'Real time'} />
              <label className={classes.label}>ACCOUNT</label>
              <PanelItem icon={ProfileIcon} label={'My Profile'} />
              <Support />
            </div>
        </React.Fragment>

    )
}

export default Panel;