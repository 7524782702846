import React, { useState } from 'react';

import Login from './components/login/Login';
import Home from './components/home/Home';


// const App = () => {
//   return (
//       <HomeDashboard />
//   );
// }

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
  };

  return (
    <div>
      {isAuthenticated ? (
        <React.Fragment>
          <Home onLogout={handleLogout} />
          </React.Fragment>
      ) : (
        <React.Fragment>
          <Login handleLogin={handleLogin}/>
        </React.Fragment>
      )}
    </div>
  );
};

export default App;
