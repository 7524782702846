import React from 'react';
import classes from "../Pages.module.css";
import StepsPanel from './StepsPanel'

const Steps = () => {

  const marks = {
    0: {
      style: {
        fontSize: '10px',
        color: '#c8c8c8'
      },
      label: (
        <div>
          <div>|</div>
          <div>Week4</div>
        </div>
      )
    },
    1: {
      style: {
        fontSize: '10px',
        color: '#c8c8c8'
      },
      label: '|'
    },
    2: {
      style: {
        fontSize: '10px',
        color: '#c8c8c8'
      },
      label: (
        <div>
          <div>|</div>
          <div>Week5</div>
        </div>
      )
    },
    3: {
      style: {
        fontSize: '10px',
        color: '#c8c8c8'
      },
      label: '|'
    },    
    4: {
      style: {
        fontSize: '10px',
        color: '#c8c8c8'
      },
      label: (
        <div>
          <div>|</div>
          <div>Week6</div>
        </div>
      )
    },
    5: {
      style: {
        fontSize: '10px',
        color: '#c8c8c8'
      },
      label: '|'
    },
    6: {
      style: {
        fontSize: '10px',
        color: '#c8c8c8'
      },
      label: (
        <div>
          <div>|</div>
          <div>Week7</div>
        </div>
      )
    },
    7: {
      style: {
        fontSize: '10px',
        color: '#c8c8c8'
      },
      label: '|'
    },    
    8: {
      style: {
        fontSize: '10px',
        color: '#c8c8c8'
      },
      label: (
        <div>
          <div>|</div>
          <div>Week8</div>
        </div>
      )
    },
    9: {
      style: {
        fontSize: '10px',
        color: '#c8c8c8'
      },
      label: '|'
    },    
    10: {
      style: {
        fontSize: '10px',
        color: '#c8c8c8'
      },
      label: (
        <div>
          <div>|</div>
          <div>NOW</div>
        </div>
      ) 
    }
  }


  return (
    <div className={classes.panel}>
      <StepsPanel title="Feet Map" marks={marks}/>
    </div>
  );
};

export default Steps;