import React from "react";
import classes from './HomeDashboard.module.css';
import { ReactComponent as HomeIcon } from '../../../assets/svg/home.svg';

const HomeDashboard = () => {
  return (
    <button className={classes.home_title}>
      <HomeIcon className={classes.title_icon} />
      <span className={classes.title_text}>Home Dashboard</span>
    </button>
  );
};

export default HomeDashboard;