import React from "react";
import classes from "./Header.module.css"
import DoctorsListView from "./DoctorsListView";

const Header = () => {
    return (
      <React.Fragment>
        <div className={classes.header}>
          <div>
             <div>
                <label className={classes.header_label_1}>Home</label>
                <label> / Patient Information</label>               
             </div>
             <div className={classes.header_label_2}>
                <label>Angelina Dow</label>
            </div>
          </div>
            <div className={classes.header_list_view}><DoctorsListView /></div>
        </div>
        <label className={classes.header_label_3}>Patient Information</label>
      </React.Fragment>
    )
}

export default Header;