import React, { useState } from 'react';
import classes from './ToggleSwitch.module.css';

const ToggleSwitch = ({ label }) => {
  const [isChecked, setIsChecked] = useState(true);

  const handleToggle = () => {
    setIsChecked(!isChecked);
  };

  return (
    <div className={classes.toggle_switch}>
      <input
        type="checkbox"
        id="switch"
        className={classes.switch_input}
        checked={isChecked}
        onChange={handleToggle}
      />
      <label htmlFor="switch" className={classes.switch_label}></label>
      {label && <span className={classes.switch_label_text}>{label}</span>}
    </div>
  );
};

export default ToggleSwitch;
