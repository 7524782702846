import React from 'react';
import classes from './Home.module.css';
import Navbar from './navbar/Navbar';
import Panel from './left-panel/Panel';
import Header from './header/Header';

function Home({ onLogout }) {
  return (
    <div className={classes.container}>
      <div className={classes.left}>
        <Panel />
      </div>
      <div className={classes.right}>
        <Header />
        <Navbar onLogout={onLogout} />
      </div>
    </div>
  );
}

export default Home;
