import React, { useState, useEffect } from 'react';
import ToggleSwitch from './ToggleSwitch';
import classes from "./LoginUser.module.css";
import Papa from 'papaparse';

const Login = ({ onLogin }) => {
  const [password, setPassword] = useState('');
  const [validPasswords, setValidPasswords] = useState([]);

  // Function to read CSV file from project directory
  useEffect(() => {
    const loadPasswords = () => {
      const filePath = '/passwords.csv'; // Path to the CSV file in the public folder

      fetch(filePath)
        .then(response => {
          if (!response.ok) {
            throw new Error('Failed to fetch the CSV file');
          }
          return response.text();
        })
        .then(csvData => {
          // Parse the CSV data
          const parsedData = Papa.parse(csvData, { header: false }).data;
          const passwords = parsedData.map(row => row[0]);
          setValidPasswords(passwords);
        })
        .catch(error => {
          console.error('Error loading passwords:', error);
        });
    };

    loadPasswords();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validPasswords.includes(password)) {
      onLogin();
    } else {
      alert('Invalid password');
    }
  };

  return (
    <form onSubmit={handleSubmit} className={classes.login_form_container}>
      <label className={classes.welcome_text}>
        Welcome Back!
      </label>
      <label className={classes.pass_inst_text}>
        Enter your email and password to sign in
      </label>
      <label className={classes.label}>
        Email
      </label>
      <input className={classes.input}
        placeholder='Your work email'
      />
      <label className={classes.label}>
        Password
      </label>
      <input className={classes.input}
        placeholder='Your password'
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <ToggleSwitch label="Remember me" />
      <button className={classes.sign_in_button} type="submit">Sign in</button>
      <div className={classes.account}>
        <label className={classes.label}>Don't have an account? </label>
        <label className={classes.green_label}>Register</label>
      </div>
    </form>
  );
};

export default Login;
