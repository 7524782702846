import React from "react";
import Bar from "./Bar";
import LoginUser from "./LoginUser";
import classes from "./Login.module.css"

function Login({handleLogin}) {
  return (
    <React.Fragment>
      <div className={classes.container}>
        <Bar />
        <LoginUser onLogin={handleLogin} />
      </div>
      <div className={classes.background_image}></div>
    </React.Fragment>
  )
}

export default Login;



