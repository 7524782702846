import React from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import './TimerSlider.css';

const TimerSlider = ({marks, onSlideChange}) => {

    const onSlideChangeHandler = (value) => {
        onSlideChange(value);
    };

    return (
        <div className='container'>
            <Slider 
                min={0}
                max={10}
                marks={marks}    
                defaultValue={10} // Set the default value at week 7
                trackStyle={{ backgroundColor: '#52c2c7' }} 
                handleStyle={{
                    borderColor: 'none',
                    backgroundColor: 'none',
                }}
                railStyle={{ backgroundColor: '#e1f3f2' }}
                onChangeComplete={onSlideChangeHandler}
                
            />
        </div>
    )
}

export default TimerSlider;