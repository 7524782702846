import React, { useState } from "react";
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Collapse,
  Paper,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import classes from "./DoctorsListView.module.css"

const ListView = () => {
  const listData = [
    { id: 1, name: "Dr. John Waklins", avatarUrl: require("../../../assets/avatars/john-waklins.png") },
    { id: 2, name: "Dra. Marie Parker", avatarUrl: require("../../../assets/avatars/marie-parker.png") },
    { id: 3, name: "Dr. Robert Williams", avatarUrl: require("../../../assets/avatars/robert-williams.png") },
  ];

  const [selectedItem, setSelectedItem] = useState(listData[0]);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const handleDropdownToggle = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setDropdownVisible(false);
  };

  const filteredListData = listData.filter((item) => item.id !== selectedItem?.id);

  return (
    <div className={classes.listview_container} onClick={handleDropdownToggle}>
      <List>
        <ListItem style={{backgroundColor: "white", border: "1px solid #e9e9e9", borderRadius: "7px"}}>
          <ListItemAvatar>
            <Avatar src={selectedItem.avatarUrl} style={{ width: 32, height: 32 }} />
          </ListItemAvatar>
          <ListItemText
            primary={selectedItem.name}
            primaryTypographyProps={{ style: { fontSize: "14px" } }}
          />
          <ListItemSecondaryAction>
            <IconButton edge="end" style={{ padding: "6px" }}>
              <ExpandMoreIcon fontSize="small" />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </List>

      <Collapse in={dropdownVisible} timeout="auto" unmountOnExit>
        <Paper className={classes.paper}>
          <List>
            {filteredListData.length > 0 ? (
              filteredListData.map((item) => (
                <ListItem
                  button
                  key={item.id}
                  onClick={() => handleItemClick(item)}
                >
                  <ListItemAvatar>
                    <Avatar src={item.avatarUrl} style={{ width: 32, height: 32 }} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={item.name}
                    primaryTypographyProps={{ style: { fontSize: "14px" } }}
                  />
                </ListItem>
              ))
            ) : (
              <ListItem style={{ padding: "8px 16px" }}>
                <ListItemText primary="No other options available" />
              </ListItem>
            )}
          </List>
        </Paper>
      </Collapse>
    </div>
  );
};

export default ListView;